/* global window, HTMLElement, document, CustomEvent */

class TabSwitcher extends HTMLElement {

	constructor() {
		super();
	}

	connectedCallback() {

		this._labels = this.querySelectorAll('label');
		this._inputs = [];
		this._activeClass = this.getAttribute('active-class');
		this._activeDivClass = this.getAttribute('active-div-class');
		
		this._setupChangeListeners();
		this._updateClasses();

	}

	_setupChangeListeners() {

		const children = this.children;

		[].forEach.call(children, (child) => {
			this._inputs.push(document.querySelector(child.getAttribute('input-selector')));
		});

		this._inputs.forEach((input) => {
			input.addEventListener('change', () => {
				this._updateClasses();

				// Fire change event. SimplePieChart listens to it and draws
				// itself as soon as it is fired. Charts can only be drawn *after*
				// the elements have a height/width, which is not the case if 
				// parent element is on display:none.
				const event = new CustomEvent('tab-switch', { bubbles: true });
				console.log('TabSwitcher: Fire tab-switch event %o', event);
				this.dispatchEvent(event);

			});
		});

	}



	_updateClasses() {

		const selected = this._inputs.filter((input) => input.checked);
		
		[].forEach.call(this.children, (child) => {

			const isSelectedChild = document.querySelector(child.getAttribute('input-selector')) === selected[0];

			if (isSelectedChild) {
				child.classList.add(this._activeDivClass);
				child.querySelector('label').classList.add(this._activeClass);
			}
			else {
				child.classList.remove(this._activeDivClass);
				child.querySelector('label').classList.remove(this._activeClass);				
			}

		});

	}

	
}

window.customElements.define('tab-switcher', TabSwitcher);

